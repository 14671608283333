// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import KnowledgeArticle from 'components/Contentful/KnowledgeArticle'
import SideNavigation from 'components/SideNavigation'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Wärmepumpen Förderbeiträge im Kanton Solothurn"
        meta={[
          { name: 'description', content: 'Von Förderbeiträgen im Kanton Solothurn profitieren. Kantonales Förderprogramm ✔️ Grundbeitrag plus leistungsabhängige Beiträge ✔️ Hier informieren ✔️' },
          { name: 'keywords', content: 'förderbeiträge kanton solothurn' },
        ]}
      >
        
      </Helmet>
      
      <div className='section__nav section--padded'><SideNavigation location={props.location} links={[
    {
        "type": "link",
        "id": "6imdAPCpzzJin32ITPF7Tv",
        "updatedAt": "2020-03-18T15:08:38.076Z",
        "label": "Schweizweite Förderung",
        "page": {
            "slug": "foerdergelder/schweizweite-foerderung"
        }
    },
    {
        "type": "link",
        "id": "4OW4jSV0Vk2H7QMmCcj8gc",
        "updatedAt": "2021-09-03T09:57:55.973Z",
        "label": "Das Gebäudeprogramm",
        "page": {
            "slug": "foerdergelder/das-gebaeudeprogramm"
        }
    },
    {
        "type": "link",
        "id": "cIQKSgk11rayF9abVfi4k",
        "updatedAt": "2020-03-27T08:01:44.514Z",
        "label": "Häufig gestellte Fragen - FAQ",
        "page": {
            "slug": "foerdergelder/haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
        }
    },
    {
        "type": "link",
        "id": "2MTu40Ef1Gc5Sl6BRdqp5e",
        "updatedAt": "2020-03-13T15:09:44.207Z",
        "label": "Fördergelder Kanton Aargau",
        "page": {
            "slug": "foerdergelder/kanton-aargau"
        }
    },
    {
        "type": "link",
        "id": "6jyOwojHZQnjIMizqwQnXQ",
        "updatedAt": "2020-03-23T09:16:33.860Z",
        "label": "Fördergelder Kanton Basel-Landschaft",
        "page": {
            "slug": "foerdergelder/kanton-basel-landschaft"
        }
    },
    {
        "type": "link",
        "id": "539aNbJu1biigQT5Unbhd2",
        "updatedAt": "2020-03-23T10:22:43.087Z",
        "label": "Fördergelder Kanton Basel-Stadt",
        "page": {
            "slug": "foerdergelder/kanton-basel-stadt"
        }
    },
    {
        "type": "link",
        "id": "2jA1lO1AcRz7oZ2sSFx9vg",
        "updatedAt": "2020-03-23T10:27:44.702Z",
        "label": "Fördergelder Kanton Bern",
        "page": {
            "slug": "foerdergelder/kanton-bern"
        }
    },
    {
        "type": "link",
        "id": "223GuzpRXRVSaAYk6BFtSq",
        "updatedAt": "2020-03-23T11:00:32.709Z",
        "label": "Fördergelder Kanton Luzern",
        "page": {
            "slug": "foerdergelder/kanton-luzern"
        }
    },
    {
        "type": "link",
        "id": "1t30LGgkuILdIGApksWHvv",
        "updatedAt": "2023-09-28T15:35:50.064Z",
        "label": "Fördergelder Kanton Schaffhausen",
        "page": {
            "slug": "foerdergelder/foerdergelder-kanton-schaffhausen"
        }
    },
    {
        "type": "link",
        "id": "11DxxtCQGVZdWGHeKaLoUq",
        "updatedAt": "2020-03-24T09:20:23.945Z",
        "label": "Fördergelder Kanton Solothurn",
        "page": {
            "slug": "foerdergelder/kanton-solothurn"
        }
    },
    {
        "type": "link",
        "id": "3mbZseYEYjKC7VQPe24HQS",
        "updatedAt": "2023-04-20T15:20:55.004Z",
        "label": "Fördergelder Kanton St. Gallen",
        "page": {
            "slug": "foerdergelder/foerdergelder-kanton-st-gallen"
        }
    },
    {
        "type": "link",
        "id": "5RPB8qgJ9nc7aoIwoFxmul",
        "updatedAt": "2023-04-20T15:21:32.792Z",
        "label": "Fördergelder Kanton Thurgau",
        "page": {
            "slug": "foerdergelder/kanton-thurgau"
        }
    },
    {
        "type": "link",
        "id": "4mUIIT6zOW58vyTDWkKUGx",
        "updatedAt": "2020-03-24T10:00:31.055Z",
        "label": "Fördergelder Kanton Zug",
        "page": {
            "slug": "foerdergelder/kanton-zug"
        }
    },
    {
        "type": "link",
        "id": "59vmXjjI3AnPXmVsC4kKBz",
        "updatedAt": "2020-03-24T10:03:26.817Z",
        "label": "Fördergelder Kanton Zürich",
        "page": {
            "slug": "foerdergelder/kanton-zuerich"
        }
    }
]} /><div
          className='bg__flex--white'
          key='3sCJcJL37OFyBBQcBCkoe6-5pWlv5PaqxjD5SEXfH2dVV'
          id=''
        >
          <KnowledgeArticle
            {...{
    "type": "knowledgeArticle",
    "id": "5pWlv5PaqxjD5SEXfH2dVV",
    "updatedAt": "2023-04-20T16:08:12.963Z",
    "title": "Fördergelder Kanton Solothurn",
    "keyword": "Fördergelder",
    "articleType": "Infoartikel",
    "description": "Im Kanton Solothurn profitieren Sie vom kantonalen Förderprogramm. Sie erhalten einen Grundbeitrag plus leistungsabhängige Beiträge.",
    "content": [
        {
            "type": "infoarticle",
            "id": "2OofTQyUMwt48KeQG047Rd",
            "updatedAt": "2023-04-28T08:35:33.465Z",
            "title": "Fördergelder Kanton Solothurn",
            "text": "<h3>Fördergelder Kanton Solothurn</h3>\n<p>Der Kanton unterstützt Bauherrschaften mit einem Grundbeitrag von <strong>4’000 Franken (Luft-/Wasser)</strong> respektive <strong>6’000 Franken (Sole-/Wasser)</strong>, wenn Sie Ihre alte Heizöl-, Erdgas- oder Elektroheizung ersetzen. Hinzu kommen leistungsabhängig Beiträge.</p>\n<h3>Förderbeiträge</h3>\n<table>\n<thead>\n<tr>\n<th>Wärmepumpe</th>\n<th>Beitrag</th>\n</tr>\n</thead>\n<tbody>\n<tr>\n<td>Luft/Wasser</td>\n<td>CHF 4'000 + CHF 150/kWth</td>\n</tr>\n<tr>\n<td>Sole/Wasser</td>\n<td>CHF 6'000 + CHF 450/kWth</td>\n</tr>\n</tbody>\n</table>\n<p>Weitere Informationen finden Sie unter folgendem <a href=\"https://energie.so.ch/foerderung/foerdermassnahmen\" title=\"Link Fördermassnahmen Kanton Solothurn\">Link</a>.</p>\n<h3>Förderstelle</h3>\n<p>Kanton Solothurn<br>\nEnergiefachstelle<br>\nRathausgasse 16<br>\n4509 Solothurn<br>\nTel: 032 627 85 24<br>\n<a href=\"http://so.ch/verwaltung/volkswirtschaftsdepartement/amt-fuer-wirtschaft-und-arbeit/energiefachstelle/\">Website</a></p>\n"
        }
    ],
    "topic": {
        "type": "link",
        "id": "3P9prSaMlzdADoC8f1gPT7",
        "updatedAt": "2021-02-11T13:38:26.956Z",
        "label": "Fördergelder",
        "page": {
            "slug": "foerdergelder"
        }
    },
    "previewImage": {
        "description": "Wappen Fördergelder Wärmepumpe Kanton Solothurn",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/31RHpzseD5iPla7Lf7yvh1/74bd2a2b29ce15aa6d81bef12019e5d7/Solothurn.png"
    },
    "previousArticle": "/foerdergelder/foerdergelder-kanton-schaffhausen",
    "nextArticle": "/foerdergelder/foerdergelder-kanton-st-gallen"
}} location={props.location}
          />
        </div></div>
    </Layout>
  )
}

export default Page
